<template>
	<div id="branchList" class="el-content">
		<a-button class="mb12" type="primary" v-has="{ action: 'sass_server_meal_add' }" @click="editMeal(0)">
			<i class="ri-add-line ri-top"></i>
			添加套餐
		</a-button>
		<a-table row-key="meal_id" :data-source="list" :columns="[
				{ title: 'ID', dataIndex: 'meal_id' },
				{ title: '套餐名称', dataIndex: 'meal_name' },
				{ title: '价格(元)', dataIndex: 'price' },
				{ title: '折扣价格(元)', dataIndex: 'discount_price' },
				{ title: '状态', dataIndex: 'is_put_away', slots: { customRender: 'is_put_away' } },
				{ title: '创建时间', dataIndex: 'create_time' },
				{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
			]"
		>
			<template #is_put_away="{record}">
				<a-tooltip title="点击设置为下架">
					<a-tag @click="changePutAway(record.meal_id)" color="#00CC66" v-if="record.is_put_away == 1">上架</a-tag>
				</a-tooltip>
				<a-tooltip title="点击设置上架">
					<a-tag @click="changePutAway(record.meal_id)" color="#FF6633" v-if="record.is_put_away == 0">已下架</a-tag>
				</a-tooltip>
			</template>
			<template #action="{record}">
				<a-button v-has="{ action: 'sass_server_meal_add' }" @click="editMeal(record.meal_id)" type="link" size="small">编辑</a-button>
				<a-button v-has="{ action: 'sass_server_meal_del' }" type="link" @click="deleteMeal(record.meal_id)" size="small">删除</a-button>
			</template>
		</a-table>
		<a-modal v-model:visible="showEditDialog" title="新增/编辑分支信息" width="700px" @ok="saveMeal" @cancel="showEditDialog = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
				<a-form-item label="选择分支">
					<com-server-select :server="server" @success="e=>form.server_id = e"></com-server-select>	
				</a-form-item>
				<a-form-item label="套餐名称" required>
					<a-input v-model:value="form.meal_name"></a-input>
				</a-form-item>
				<a-form-item label="价格">
					<a-input v-model:value="form.price"></a-input>
				</a-form-item>
				<a-form-item label="折扣价格">
					<a-input v-model:value="form.discount_price"></a-input>
				</a-form-item>
				<a-form-item label="可创建应用数量">
					<a-input v-model:value="form.limit"></a-input>
				</a-form-item>
				<a-form-item label="是否上架">
					<a-radio-group v-model:value="form.is_put_away">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="描述" >
					<a-textarea v-model:value="form.description" :rows="4" />
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import serviceModel from '@/api/saas/service.js'
import comServerSelect from '@/components/admin/com-server-select.vue'
export default{
	components:{
		comServerSelect
	},
	setup(){
		const _d = reactive({
			list:[],
			showEditDialog:false,
			form:null,
			branchList:[],
			server:null
		})
		getMealList()
		function getMealList(){
			serviceModel.getBranch("",2,res=>_d.list = res)
		}

		function editMeal(pk){
			if( pk ){
				serviceModel.getBranchDetail(pk,2,res=>{
					_d.form = {
						price:res.price,
						limit:res.limit,
						server_id:res.server_id,
						meal_id:res.meal_id,
						meal_name:res.meal_name,
						description:res.description,
						is_put_away:res.is_put_away,
						discount_price:res.discount_price,
					}

					//回显使用
					serviceModel.getBranchDetail(res.server_id,1,resutl=>_d.server = resutl)
					_d.showEditDialog = true
				})
			}else{
				clearForm()
				_d.showEditDialog = true
			}
		}

		function clearForm(){
			_d.form = {
				price: '',
				limit: '',
				meal_id: 0,
				server_id: '',
				is_put_away: 0,
				meal_name: '',
				description: '',
				discount_price: ''
			}
			
		}

		const saveMeal = ()=>serviceModel.addOrEditMeal(JSON.parse(JSON.stringify(_d.form)),()=>{
			_d.showEditDialog = false
			getMealList()
		})
		const deleteMeal = (id)=>serviceModel.handleMeal(id,1,()=>getMealList())
		const changePutAway = (id)=>serviceModel.handleMeal(id,2,()=>getMealList())
		return{
			...toRefs(_d),
			editMeal,
			getMealList,
			saveMeal,
			deleteMeal,
			changePutAway,
			clearForm,
		}
	}
}
</script>

<style>
</style>
